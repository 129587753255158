import React from "react"
import Layout from "../components/layout"
import Content_surround from "../components/content_surround"
//import {Link} from "gatsby"

export default function About() {
  return (
    <Layout  image="https://admhotels.com/images/adm_business_1.jpeg" page_title="The ADM Team">
      <div className="stdSection">
        <h1 className="text-center">Learn More About the ADM Team</h1>
        <p className="text-center">Experienced Professionals. Driven to Succeed.</p>
        <Content_surround>
            <div className="text-center">
                <img src="https://admhotels.com/images/adm-main/headshots/mark-jacob-thomas-hs.jpg" alt="" width="200px"/>
            </div>
            
            <h2 className="text-center">Mark Jacob Thomas</h2>
            <p className="text-center member_title" >Principal and Founding Member</p>
            
            <div style={{maxWidth:'400px',textAlign:'center',margin:'auto'}}>
            <p>In Memoriam</p>
                <p>"And Jesus looking upon them saith, With men it is impossible, but not with God: for with God all things are possible"</p>
                <p style={{textAlign:'right'}}>-Mark 10:27</p>
            </div>


            <p>Prior to his passing Mark Jacob Thomas was a founding Principal owner and Chief Executive Officer of ADM Hotels and Resorts Group, based in South Bend, Indiana. Prior to his appointment as president and CEO, Mr. Thomas was a president and managing director of the Thomas & Associates Law firm in Chicago for last 34 years responsible for the operations of Law office for Immigration nationwide. Throughout his career as Immigration and International Business law, Mr. Thomas has been instrumental in developing the relation with all international culture and communities in the USA. Mr. Thomas ranked among the top 100 Immigration Attorneys nationwide.</p>
        </Content_surround>

        <Content_surround>
            <div className="text-center">
                <img src="https://admhotels.com/images/2020/06/21/nausher-ahmad2.jpg" alt="" width="200px"/>
            </div>
            <h2 className="text-center">Nausher Ahmad Sial</h2>
            <p className="text-center member_title">Principal and Founding Member</p>
            <p>Chief Executive Officer of ADM Hotels and Resorts Group, based in South Bend, Indiana.</p>
        </Content_surround>
{/*
        <Content_surround>
            <h2 className="text-center">Jerry Dominiack Sr.</h2>
            <p className="text-center member_title">Principal/Managing Partner</p>
            <p>Chief Executive Officer of Blackthorn Development Group and Managing Partner ADM Hotels and Resorts Group, based in South Bend, Indiana.</p>
        </Content_surround>
      */}
      </div>
    </Layout>
  )  
  }