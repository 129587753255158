import React from "react"
import content_surroundStyles from "./content_surround.module.css"
import {Link} from "gatsby"

function renderLink(props){
    if(props.goTo){
        return  <Link to={props.goTo} className={content_surroundStyles.button}>Learn More</Link> 
    }
}

export default function Content_surround(props){
    return(
        <div className={content_surroundStyles.content_surround}>
            <div className={content_surroundStyles.content}>
                {props.children}
            </div>
            {renderLink(props)}

            
            <div className={content_surroundStyles.border_left}></div>
            <div className={content_surroundStyles.border_right}></div>
        </div>

    )
} 